<template>
  <LayoutDefault>
    <!-- Content Wrapper. Contains page content -->
    <Loading :active="loading" />
    <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header mt-2">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-xs-12 col-sm-6 mb-3 mb-md-0">
              <h1 class="m-0 text-dark ttU">Faqs</h1>
            </div>
            <!-- /.col -->
            <div class="col-xs-12 col-sm-6">
              <!-- <button type="button" class="btn btn-default btn-default-invert float-right ml-2 mb-2 mb-md-0">Importar</button> -->
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->
      <section class="content">
        <div class="row">
          <!-- /.col -->
          <div class="col-md-12">
            <div class="card card-primary card-outline">
              <div class="card-header">
                <router-link
                  :to="{ name: 'FaqsCreate' }"
                  class="btn btn-default btn-default-invert float-right ml-2 mb-2 mb-md-0"
                >
                  Crear Faq
                </router-link>
                <!-- <div class="card-tools">
                  <div class="input-group input-group-sm">
                    <input type="text" class="form-control" placeholder="Buscar faq">
                    <div class="input-group-append">
                      <div class="btn btn-primary">
                        <i class="fas fa-search"></i>
                      </div>
                    </div>
                  </div>
                </div> -->
                <!-- /.card-tools -->
              </div>
              <!-- /.card-header -->
              <div class="card-body p-0">
                <div class="table-responsive mailbox-messages">
                  <table id="faqs" class="table table-hover table-striped">
                    <thead>
                      <tr>
                        <th>Editar</th>
                        <th>Pregunta</th>
                        <th>Respuesta</th>
                        <th>Eliminar</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(faq, index) in faqs" :key="index">
                        <td>
                          <router-link
                            :to="{
                              name: 'FaqsEdit',
                              params: { faq_id: faq.id },
                            }"
                            >Editar</router-link>
                        </td>
                        <td>{{ faq.question }}</td>
                        <td>{{ faq.answer }}</td>
                        <td>
                          <button
                            class="btn-delete btn btn-danger"
                            @click="deleteFaq(faq.id)"
                          >
                            <i class="nav-icon fas fa-trash"></i>
                          </button>
                        </td>
                      </tr>
                      <tr v-if="faqs.length === 0">
                        <td>Ningún dato disponible</td>
                      </tr>
                    </tbody>
                  </table>
                  <!-- /.table -->
                </div>
                <!-- /.mail-box-messages -->
              </div>
              <!-- /.card-body -->
            </div>
          </div>
          <!-- /.card -->
        </div>
        <!-- /.col -->
      </section>
      <!-- /.content -->
    </div>
  </LayoutDefault>
</template>
<script>
import LayoutDefault from "../layout/LayoutAdmin";
import Loading from "../components/Loading";
import "datatables.net";
import faqsService from "../services/faqsService";
export default {
  name: `Faqs`,
  data() {
    return {
      table: {},
      faqs: [],
      loading: false,
    };
  },
  async mounted() {
    this.loading = true;
    await this.getAllFaqs();
    this.loading = false;
  },
  methods: {
    addDataToTable(data) {
      this.table.clear();
      data.forEach((el) => {
        this.table.row
          .add([
            `<a href='faqs/edit/${el.id}' >Editar</a>`,
            el.id,
            el.question,
            el.answer,
            `<a href='faqs/delete/${el.id}' >Eliminar</a>`,
          ])
          .draw(false);
      });
    },
    async deleteFaq(id) {
      this.loading = true;
      await faqsService.deleteFaq(id);
      await this.getAllFaqs();
      this.loading = false;
    },
    async getAllFaqs() {
      const res = await faqsService.getAllFaqs();
      if (res && res.response) {
        this.faqs = res.response;
        if (res.response.data) {
          this.faqs = res.response.data;
        }
      }
    }, // 1
  },
  components: {
    LayoutDefault,
    Loading,
  },
};
</script>
<style lang="scss">
.dataTables_length,
.dataTables_filter {
  padding: 0.8em 1em;
}
.card-primary.card-outline {
  border-top: 3px solid #ff6900;
}
.nav-pills .nav-link:not(.active):hover {
  color: #ff6900;
}
.nav-pills .nav-item.active .nav-link {
  color: #ff6900;
}
.nav-pills {
  .nav-link {
    cursor: pointer;
  }
  .badge {
    background-color: #ff6900 !important;
  }
}
table {
  td {
    position: relative;
    .btn-delete{
      right: inherit;
    }
    a {
      color: #ff6900;
    }
  }
}
</style>
